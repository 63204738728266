import Comment, { canDeleteComment } from 'components/Comment/Comment';
import InboxBugStatusSelection from 'components/InboxBugStatusSelection/InboxBugStatusSelection';
import InboxSnoozeButton from 'components/InboxSnoozeButton/InboxSnoozeButton';
import LinkButton from 'components/LinkButton/LinkButton';
import SendInputEditor from 'components/SendInputEditor/SendInputEditor';
import PublicSkeleton from 'components/Skeletons/PublicSkeleton';
import { debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import { CommentTypes } from 'models/Comment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { uploadFileToServer } from 'services/FileUpload';
import { BugStore } from 'stores/private/BugStore';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { PropertyStore } from 'stores/private/PropertyStore';
import { UsersStore } from 'stores/private/UsersStore';
import './Comments.scss';
import './Details.scss';
import TicketInfoComment from './TicketInfoComment';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { isMacintosh } from 'services/Helper';

interface CommentsProps {
  shared?: boolean;
  showHistory?: boolean;
  bugStore?: BugStore;
  usersStore?: UsersStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  organisationStore?: OrganisationStore;
  isInbox?: boolean;
  propertyStore?: PropertyStore;
}

export const hasSameCreator = (comment, otherComment) => {
  if (
    comment.session &&
    otherComment.session &&
    comment.session.id &&
    otherComment.session.id &&
    comment.session.id === otherComment.session.id
  ) {
    return true;
  }
  if (
    comment.user &&
    otherComment.user &&
    comment.user.email &&
    otherComment.user.email &&
    comment.user.email === otherComment.user.email
  ) {
    return true;
  }
  return false;
};

export const getReporterInfo = (bug) => {
  if (bug && bug.session) {
    return {
      name: bug.session.name,
      email: bug.session.email,
      isOnline: bug.session?.lastActivity
        ? (Date.now() - Date.parse(bug.session?.lastActivity)) / 60000 < 2
        : false,
    };
  }

  return {
    name: 'Not set',
    email: '--',
    isOnline: false,
  };
};

const Comments = ({
  shared = false,
  showHistory = false,
  bugStore,
  usersStore,
  projectStore,
  organisationStore,
  modalStore,
  isInbox,
  propertyStore,
}: CommentsProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [newTicketTitle, setNewTicketTitle] = useState('');
  const [titleFocus, setTitleFocus] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isNote, setIsNote] = useState(false);
  const textareaRef = useRef(undefined as any);
  const [userSession, setUserSession] = useState(undefined as any);
  const [textfieldHeight, setTextfieldHeight] = useState(80);
  const [laneKeys, setLaneKeys] = useState([] as any[]);
  const attachmentsArrayRef = useRef([] as File[]);
  const [attachments, setAttachments] = useState([] as File[]);
  attachmentsArrayRef.current = attachments;
  const [feedbackTypes, setFeedbackTypes] = useState([] as any[]);
  const commentsRef = useRef(null as any);
  const bug = bugStore!.currentBug;
  const comments = bugStore!.currentComments;
  const loadingComments = bugStore!.loadingComments;
  const attachmentsRef = useRef();
  const textComposerRef = useRef();
  const containerRef = useRef(null);
  const [isContainerSmall, setIsContainerSmall] = useState(false);

  useEffect(() => {
    if (bug?.title && bug?.title.length > 0) {
      setNewTicketTitle(bug.title);
    }
  }, [bug?.title]);

  const sessionData = new URLSearchParams(location.search);
  const gleapId = sessionData.get('u');
  const gleapHash = sessionData.get('h');

  const updateEditorContent = (content) => {
    if (textComposerRef.current) {
      (textComposerRef.current as any).updateContent(content);
    }
  };

  const userTypingPing = async (typing: boolean) => {
    if (!bug) {
      return;
    }

    if (!usersStore?.currentUser?.id) {
      return;
    }

    bugStore!.userIsTypingInTicket(bug.id, typing);
  };

  const debouncedUserTypingPing = useCallback(
    debounce(
      (json: any) => {
        var isTyping = false;

        if (json && json.content && json.content.length > 0) {
          if (!(json.content.length === 1 && !json.content[0].content)) {
            isTyping = true;
          }
        }

        return userTypingPing(isTyping);
      },
      800,
      {
        leading: true,
        trailing: false,
      },
    ),
    [],
  );

  useEffect(() => {
    (window as any).onMessagePaste = (message: any) => {
      updateEditorContent(message);
    };
  }, []);

  useEffect(() => {
    const currentFeedbackType = projectStore!.findFeedbackTypeForType(
      bug?.type,
    );

    if (
      currentFeedbackType &&
      currentFeedbackType.options &&
      currentFeedbackType.options.possibleLanes
    ) {
      setLaneKeys(currentFeedbackType.options.possibleLanes);
    }

    if (projectStore?.currentProject?.projectTypes) {
      setFeedbackTypes(projectStore?.currentProject?.projectTypes);
    }
  }, [bugStore?.currentBug, projectStore?.currentProject]);

  const scrollCommentsViewToBottom = () => {
    setTimeout(() => {
      if (
        comments &&
        comments.length > 0 &&
        commentsRef &&
        commentsRef.current
      ) {
        if ((commentsRef.current.scrollTop ?? 0) === 0) {
          commentsRef.current!.scroll({
            top: commentsRef.current!.scrollHeight * 0.75,
            left: 0,
            behavior: 'auto',
          });
        }
        commentsRef.current!.scroll({
          top: commentsRef.current!.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  useEffect(() => {
    const debouncedHandleResize = debounce(function (entries) {
      const entry = entries[0];
      setIsContainerSmall(entry.contentRect.width < 470);
    }, 20);

    const observer = new ResizeObserver((entries) =>
      debouncedHandleResize(entries),
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
    scrollCommentsViewToBottom();
  }, [textfieldHeight]);

  useEffect(() => {
    ReactTooltip.rebuild();
    scrollCommentsViewToBottom();
  }, [comments?.length]);

  useEffect(() => {
    if (gleapId && gleapHash) {
      setUserSession({
        gleapId,
        gleapHash,
      });
    }
  }, [gleapId, gleapHash]);

  useEffect(() => {
    if (bug) {
      // Get last decision.
      try {
        const isNote = localStorage.getItem(`isnote_n${bug.id}`);
        if (isNote && isNote === 'true') {
          setIsNote(true);
        } else {
          setIsNote(false);
        }
      } catch (exp) {}
    }
  }, [bug]);

  const setNoteDraft = (commentValue: any) => {
    try {
      // Update in local storage.
      const stringifiedJson = JSON.stringify(commentValue);
      localStorage.setItem(`note_draft_n${bug?.id}`, stringifiedJson);
    } catch (exp) {}
  };

  const sendComment = async (
    json: any,
    shouldCloseConversation = false,
    shouldArchiveConversation = false,
  ) => {
    if (!bug) {
      return;
    }

    const comment = json;
    if (!comment || comment.length === 0) {
      return;
    }

    setIsSending(true);

    if (!usersStore?.currentUser?.id) {
      bugStore!.addSharedCommentWithSession(
        bug.shareToken,
        userSession.gleapId,
        userSession.gleapHash,
        comment,
      );
    } else {
      // Upload attachments.
      var uploadedAttachments = [] as any[];
      if (attachmentsArrayRef && attachmentsArrayRef.current) {
        for (let i = 0; i < attachmentsArrayRef.current.length; i++) {
          const file = attachmentsArrayRef.current[i];
          const uploadedAttachment = await uploadFileToServer(
            file,
            `${bug.id}/attachments`,
          );
          if (uploadedAttachment) {
            uploadedAttachments.push({
              url: uploadedAttachment,
              name: file.name,
              type: file.type,
            });
          }
        }
      }

      bugStore!.addCommentToBug(bug.id, comment, isNote, uploadedAttachments);
    }

    if (attachmentsRef && attachmentsRef.current) {
      (attachmentsRef.current as any).clearFiles();
    }

    updateEditorContent({});
    setNoteDraft('');
    if (textareaRef && textareaRef.current) {
      setTextfieldHeight(80);
      textareaRef.current.style.height = `80px`;
    }

    setAttachments([]);
    setIsSending(false);
    userTypingPing(false);

    setTimeout(() => {
      updateEditorContent({});
      setNoteDraft('');

      if (shouldCloseConversation) {
        // Close conversation.
        bugStore!.updateBug(bug.id, {
          status: 'DONE',
        });
        bugStore!.clearCurrentBug();
      }
      if (shouldArchiveConversation) {
        // Archive conversation.
        bugStore!.archiveBug(bug.id);
        bugStore!.clearCurrentBug(false);
      }
    }, 250);
  };

  const renderCommentComposer = () => {
    const getTextPlaceholder = () => {
      if (userSession) {
        return 'Reply directly to our team...';
      }

      if (isNote) {
        return "Type @ to mention a teammate and they'll be notified.";
      }

      if (bug?.type === 'FEATURE_REQUEST') {
        return `Send a message to all subscribers of this feature request...`;
      }

      if (bug?.session?.email) {
        return 'Reply to the user...';
      }

      return 'Reply to the user...';
    };

    if (shared && usersStore?.currentUser?.id) {
      return (
        <div className="not-logged-in">
          <span>Hi there 👋. Please click the button below to reply.</span>
          <LinkButton
            label="Reply to the user"
            onClick={() => {
              navigate(`/projects/${bug?.project}/bugs/${bug?.shareToken}`);
            }}
          />
        </div>
      );
    }

    if (shared && !userSession) {
      return (
        <div className="not-logged-in">
          <span>Hi there 👋. Please log in to reply.</span>
          <LinkButton
            label="Log in"
            onClick={() => {
              navigate('/login');
            }}
          />
        </div>
      );
    }

    const getReplyTypes = () => {
      if (userSession) {
        return [];
      }

      if (bug?.type === 'FEATURE_REQUEST') {
        return [`Send message to subscribers`, 'Note'];
      }

      return ['Reply', 'Note'];
    };

    const replyTypes = getReplyTypes();

    if (!bug || loadingComments) {
      return null;
    }

    return (
      <SendInputEditor
        draftId={`note_draft_n${bug.id}`}
        ref={textComposerRef}
        canSendAndClose={true}
        ticketId={bugStore?.currentBug?.id ?? ''}
        sessionId={bugStore?.currentBug?.session?.id}
        disableEmailFallback={
          projectStore?.currentProject?.disableEmailFallback ?? false
        }
        isSending={isSending}
        inputTypes={replyTypes}
        recepients={
          bug?.sessions
            ? bug?.session
              ? [bug?.session, ...bug.sessions]
              : bug.sessions
            : []
        }
        allowSend={bug !== undefined}
        currentInputType={isNote ? 'Note' : replyTypes[0]}
        inputTypeChanged={(inputType) => {
          setIsNote(inputType === 'Note');

          try {
            localStorage.setItem(
              `isnote_n${bug?.id}`,
              inputType === 'Note' ? 'true' : 'false',
            );
          } catch (exp) {}
        }}
        variables={{
          project: projectStore?.currentProject ?? {},
          session: bugStore?.currentBug?.session ?? {},
          user: usersStore?.currentUser ?? {},
        }}
        onFilesSelected={(files) => {
          setAttachments([...files, ...attachments]);
        }}
        attachmentsUpdated={(files) => {
          setAttachments([...files]);
        }}
        currentlyTyping={bugStore?.currentlyTyping}
        attachmentsRef={attachmentsRef}
        attachments={attachments}
        onClickSend={sendComment}
        shared={shared}
        showAttachments={usersStore?.currentUser?.id !== undefined}
        inputContentChanged={(json) => {
          setNoteDraft(json);
          if (!isNote) {
            debouncedUserTypingPing(json);
          }
        }}
        inputPlaceholder={getTextPlaceholder()}
        mentions={
          projectStore?.currentProjectUsers
            ? projectStore?.currentProjectUsers.map((userItem) => {
                return {
                  label: `${userItem.firstName} ${userItem.lastName}`,
                  id: userItem.id,
                  email: userItem.email,
                  profileImageUrl: userItem.profileImageUrl,
                };
              })
            : []
        }
      />
    );
  };

  const renderComments = () => {
    const filteredComments = (comments ?? []).filter((comment) => {
      if (showHistory || comment.type !== CommentTypes.FEEDBACK_UPDATED) {
        return comment;
      }

      return null;
    });

    return (
      <div
        className="comments-container custom-scrollbar"
        ref={commentsRef}
        style={{
          height: `calc(100% - ${textfieldHeight + (shared ? 66 : 66) + 50}px)`,
        }}
      >
        <TicketInfoComment
          ticket={bug}
          shared={shared}
          loadingComments={loadingComments}
          smallContainer={isContainerSmall}
        />
        {bug &&
          filteredComments.map((comment, index) => {
            var sameCreator = false;
            if (index - 1 >= 0) {
              sameCreator = hasSameCreator(
                comment,
                filteredComments[index - 1],
              );
            }

            return (
              <Comment
                hideCreator={sameCreator}
                associatedBug={bugStore?.currentBug}
                disableEmailFallback={
                  projectStore?.currentProject?.disableEmailFallback ?? false
                }
                shared={shared}
                showOriginalEmail={(originalEmailContent) => {
                  modalStore?.openModalSmart(MODALTYPE.SOURCE_EMAIL, {
                    originalEmailContent: originalEmailContent,
                  });
                }}
                smallContainer={isContainerSmall}
                comment={comment}
                onDeleteComment={canDeleteComment(
                  comment,
                  usersStore?.currentUser?.id,
                  () => {
                    bugStore!.deleteCommentFromBug(bug!.id, comment.id);
                  },
                )}
                systemActionCallback={(data) => {
                  if (data.type === 'FAQ') {
                    bugStore!.respondToFAQSuggestionComment(
                      bug!.id,
                      comment.id,
                      data.accept,
                    );
                  }
                }}
                key={comment.id}
                possibleStatusList={laneKeys}
                feedbackTypes={feedbackTypes}
              />
            );
          })}
        {!bug || loadingComments ? (
          <div className="loading-comments">
            <div className="loading-comment">
              <PublicSkeleton
                width={34}
                height={34}
                count={1}
                style={{
                  borderRadius: '100%',
                  marginRight: '10px',
                }}
              />
              <PublicSkeleton
                height={55}
                width={'65vw'}
                count={1}
                style={{
                  borderRadius: '12px',
                  width: '65vw',
                  maxWidth: '320px',
                }}
              />
            </div>
          </div>
        ) : (
          <>
            {bug?.slaBreached && (
              <div className="summarize-button">
                <div className="sla-breached">
                  <i className="fa-solid fa-triangle-exclamation" /> SLA
                  breached, please respond asap.
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderTitleEditor = () => {
    if (!bug) {
      return null;
    }

    if (bug.type === 'FEATURE_REQUEST') {
      return;
    }

    const unsavedTitleChanges = newTicketTitle !== bug?.title ? true : false;
    const hasTitle = bug.title && bug.title.length > 0 ? true : false;

    const save = () => {
      if (!unsavedTitleChanges) {
        return;
      }

      bugStore?.updateBug(bug!.id, {
        title: newTicketTitle,
      });
      setNewTicketTitle('');
      setTitleFocus(false);
    };

    if (shared || !titleFocus) {
      return (
        <div
          className={`ticket-headline-input ${
            !hasTitle && 'ticket-headline-input--untitled'
          } ${!shared && 'ticket-headline-input--hover'}`}
          onClick={() => {
            if (!shared) {
              setTitleFocus(true);
            }
          }}
        >
          <div className="LinesEllipsis">
            {hasTitle ? bug.title : 'Untitled'}
          </div>
        </div>
      );
    }

    return (
      <>
        <div
          className={`ticket-headline-input ticket-headline-input--hover ${
            unsavedTitleChanges ? 'ticket-headline-input--unsaved' : ''
          } ${titleFocus ? 'ticket-headline-input--focus' : ''}`}
        >
          <input
            type="text"
            value={newTicketTitle}
            onChange={(e) => {
              setNewTicketTitle(e.target.value);
            }}
            placeholder="Untitled"
            autoFocus
            onFocus={() => setTitleFocus(true)}
            onBlur={() => {
              setTitleFocus(false);
              save();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                save();
              }
            }}
          />
          {unsavedTitleChanges && (
            <PrimaryButton
              className="ticket-headline-input-save"
              onClick={() => {
                save();
              }}
              icon="check"
            />
          )}
        </div>
      </>
    );
  };

  const markConversationAsUnread = () => {
    if (!bug) {
      return;
    }

    bugStore!.updateBug(bug.id, {
      notificationsUnread: true,
    });
    bugStore!.clearCurrentBug();
  };

  const renderUnreadButton = () => {
    return (
      <>
        <ReactTooltip
          id="unreadButtonTooltip"
          className="infoTooltip infoTooltipButton"
          delayHide={0}
          place="bottom"
          offset={{ top: -10, left: 0 }}
          type="light"
          effect="solid"
          getContent={(content) => {
            return (
              <div className="send-key-tooltip">
                <span>{content}</span>
                <div className="hotkey-help">
                  {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                  <div>Shift</div>
                  <div>L</div>
                </div>
              </div>
            );
          }}
        />
        <div data-for="unreadButtonTooltip" data-tip={'Set as unread'}>
          <LinkButton
            className="bfw ml-5 mr-5"
            iconSideRight={false}
            icon="eye-slash"
            onClick={() => {
              markConversationAsUnread();
            }}
          />
        </div>
      </>
    );
  };

  return (
    <div className="notes-container" ref={containerRef}>
      <div className="feedback-header">
        {bug?.type !== 'INQUIRY' && (
          <span className="ticketid">{bug && `#${bug.bugId}`}</span>
        )}
        {renderTitleEditor()}
        {!isContainerSmall && shared && (
          <button
            className="link-button hide-on-desktop"
            onClick={() => {
              modalStore!.openModal(MODALTYPE.MOBILE_BUG_ACTIONS);
            }}
          >
            <i className="fa-solid fa-circle-info"></i>
          </button>
        )}
        {!isContainerSmall && bug?.archived && <div style={{ flexGrow: 1 }} />}
        {bug?.archived && (
          <div className="archived-tag">
            <i className="fa-solid fa-box-archive" />
            Archived
          </div>
        )}
        {bug?.duplicateOf && bug?.duplicateOf.length > 0 && (
          <div className="archived-tag">
            <i className="fa-solid fa-clone" />
            Duplicate
          </div>
        )}
        {bug && !shared && (
          <div className="feedback-header-action-items">
            {!isContainerSmall && !bug.archived && (
              <InboxSnoozeButton isInbox={isInbox} />
            )}
            {renderUnreadButton()}
            <InboxBugStatusSelection isInbox={isInbox} />
          </div>
        )}
      </div>
      {renderComments()}
      {renderCommentComposer()}
      <ReactTooltip
        id="commentTooltip"
        className="infoTooltip"
        delayHide={500}
        type="light"
        effect="solid"
        html
      />
      <ReactTooltip
        id="unknownTooltip"
        className="infoTooltip"
        delayHide={500}
        type="light"
        effect="solid"
        getContent={(content) => {
          let data: any = {};

          try {
            data = JSON.parse(content);
          } catch (exp) {}

          return (
            <div className="unknown-tooltip">
              <div className="text">{data?.label}</div>
              <div className="mt-5">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();

                    propertyStore?.setCurrentEditingProperty({
                      visability: {
                        sidebar: true,
                        detail: true,
                        create: true,
                        card: false,
                        sharedSidebar: false,
                        sharedDetail: false,
                      },
                      fieldId: data?.fieldId,
                      path: data?.path,
                      targetSource: data?.type,
                      label: data?.label,
                    } as any);
                    modalStore?.openModalSmart(
                      MODALTYPE.PROPERTY_CONFIGURATION,
                      {
                        targetSource: data?.type,
                      },
                    );
                  }}
                >{`Add attribute to ${data?.typeName}`}</a>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default inject(
  'bugStore',
  'usersStore',
  'projectStore',
  'organisationStore',
  'modalStore',
  'propertyStore',
)(observer(Comments));
